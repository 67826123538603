
#sitemap{
  @include media-breakpoint-up(lg) {

    margin-bottom: 3rem;
    ul{
      position: relative;
      margin: 0 0 0 30px;
      list-style-type: none;
      display: block;
      margin-left: 0;
      li{
        margin-bottom: 10px;
      }

      a{
        background-color: $dark;
        color: white;
        display: inline-block;
        padding: 0 15px 0 15px;
        line-height: 40px;
        text-decoration: none;
        border-radius: 8px;
        transition: all .3s ease;
        &:hover{
          background-color: $primary;
        }
      }


      ul{
        padding-top: 10px;
        margin: 0 0 0 30px;
        &::before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 2px;
          height: 10px;
          background-color: #c8cdd2;
        }
        &::after{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 2px;
          height: 100%;
          margin-top: -20px;
          background-color: #c8cdd2;
          z-index: -1;
        }
        li{
          position: relative;
          margin-top: 10px;
          padding-left: 20px;
          &::before{
            content: '';
            position: absolute;
            top: 20px;
            left: -32px;
            width: 50px;
            height: 2px;
            background-color: #c8cdd2;
          }
        }
      }
    }
  }
}