.fobi-form{
  margin-bottom: 2rem;
  .form-required-field-message-wrapper{
    padding: .5rem;
    font-size: 90%;
    background-color: rgba($blue, .3);
    border: 1px solid rgba($blue, .7);
    margin-bottom: 10px;
    color: $secondary;
    p{
      margin-bottom: 0;
    }
  }
  .form-control{
    border-radius: 0;
  }
  .form-group{
    margin-bottom: 20px;
    label{
      font-family: $text_medium;
      font-size: 90%;
      display: inline-block;
      margin-bottom: 5px;
    }
    &.has-error{
      color: $danger;
      input{
        border-color: $danger;
      }
    }
    .help-block{
      font-size: 12px;
      margin-top: 0.5rem;
      font-style: italic;
    }
  }
  .required-field{
    color: $danger;
    display: inline-block;
    padding: 0.1rem;
  }
  .form-button-wrapper{
    display: flex;
    justify-content: flex-end;
  }
  .alert{
    border-radius: 0;
    font-size: 90%;
    padding: 0.5rem;
    h4{
      font-size: 18px;
    }
  }
}
