.blog-latest-entries {
  margin-top: 2rem;
  display: grid;
  gap: 1rem;
  margin-bottom: 2rem;
  @include media-breakpoint-up(sm) {
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }
  .blog-visual, .event__visual{
    a{
      display: block;
    }
  }
}
//blog

.posts__wrapper {
  display: grid;
  margin-bottom: 2rem;
  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 300px;
    grid-template-rows: auto repeat(2, 1fr);
    gap: 1rem;
    header { grid-area: 1 / 1 / 2 / 3; }
    main { grid-area: 2 / 1 / 4 / 2; }
    aside { grid-area: 2 / 2 / 4 / 3; }
  }
  .blog__list {
    display: grid;
    grid-auto-rows: max-content;
    gap: 1rem;
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }
    article {
      box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
      a {
        text-decoration: none;
      }
      h3 {
        line-height: 1.2;
        font-size: 20px;
        a {
          color: inherit;
        }
      }
      .blog-visual {
        margin-bottom: 10px;
        img {
          max-width: 100%;
          height: auto;
        }
      }
      .post__categories {
        display: flex;
        flex-wrap: wrap;
        gap: 0.25rem;
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 5px;
        font-size: 90%;
        font-weight: bold;
      }
    }

  }
  .pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    grid-column: 1 / -1;
    gap: 1rem;
    a {
      text-decoration: none;
    }
  }
  aside {
    padding-left: 10px;
    h3 + ul{
      margin-top: 2rem;
    }
    .blog-empty{
      text-align: center;
    }
    ul {
      list-style-type: none;
      padding-left: 0;
      li{
        margin-bottom: 0.4rem;
      }
      a {
        text-decoration: none;
        font-weight: bold;
        display: flex;
        font-size: 90%;
        justify-content: space-between;
        gap: 1rem;
        span{
          font-size: 12px;
        }
      }
    }
  }
  .blog__categories{
    padding-bottom: 2rem;
    h3{
      display: none;
    }
    ul{
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
      a{
        text-decoration: none;
        text-transform: uppercase;
        font-family: $text_medium;
        letter-spacing: 1px;
        display: block;
        padding: 0.5rem 2rem;
        background-color: $bg-gray;
        border-radius: 2rem;
        font-size: 80%;
        color: inherit;
        transition: all .4s ease;
        span{
          display: none;
        }
        &:hover{
          background-color: $primary;
          color: white;
        }
      }
    }
  }
}

.post__footer {
  margin-bottom: 20px;
}


#id_content{
  .blog__post{

    overflow: hidden;
    position: relative;
    .blog-visual{
      clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%, 0 75%);
      img{
        transition: all 0.4s ease;
        min-width: 100%;
      }
    }
    .post__content{
      padding: 1rem;
      .post__categories{
        display: flex;
        gap: 1rem;
        a{
          font-size: 80%;
          display: inline-block;
          color: white;
          background-color: $primary;
          padding: 0.2rem 0.6rem;
          border-radius: 5px;
        }
      }
      h3{
        transition: all 0.4s ease;
        a{
          color: inherit;
          text-decoration: none;
          font-size: 16px;
        }
      }
      p{
        line-height: 1.3;
      }
    }
    .read__link{
      font-weight: bold;
      text-decoration: none;
      font-size: 90%;
      margin-top: auto;
    }
    &:hover{
      .blog-visual{
        img{
          transform: rotate(-5deg) scale(1.2 , 1.2);
        }
      }
      h3{
        color: $primary;
      }
    }
  }
  .event,
  .blog__post{
    background-color: #fff;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
  }
  .event{
    .event__visual{
      position: relative;
      overflow: hidden;
      background-color: $dark;
      a {
        display: block;
        min-height: 300px;
      }
      img{
        transition: all 0.4s ease;
      }
      &::after{
        pointer-events: none;
        content: "";
        background-image: linear-gradient(179deg, rgba(0,0,0,0.00) 0%, #000000 100%);
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 40%;
      }
    }
    .event__content{
      position: absolute;
      bottom: 0;
      left: 0;right: 0;
      padding: 1rem;
      padding-top: 3rem;
      transition: all 0.4s ease;
      color: white;
      text-align: center;
      pointer-events: none;

      h3{
        transition: all 0.4s ease;
        a{
          font-family: $text_medium;
          letter-spacing: .1rem;
          text-decoration: none;
          color: inherit;
          font-size: 16px;
          text-transform: uppercase;
        }
      }
    }
    &:hover{
      h3{

      }
      .event__content{
        transform: translateY(-100px);
      }
      img{
        transform: rotate(-5deg) scale(1.2 , 1.2);
        opacity: .33;
      }
    }
  }
  .event__date{
    color: $yellow;
    font-family: $text_black;
    .date-range{
      display: flex;
      justify-content: center;
      gap: .2rem;
    }
    .date-range-to, .date-range-from{
      font-family: $text;
    }
  }
  .related-posts .event__date .date-range {
    display: flex;
    justify-content: start;
  }
}

.app-blog{
  aside{
    .plugin{
      background-color: $bg-gray;
      padding: 2rem;
      margin-bottom: 2rem;
      h3{
        text-align: center;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
      ul{
        a{
          color: inherit;
        }
      }
      .year {
        margin-top: 2rem;
        font-weight: normal;
        font-size: 1.2em;
      }
    }
  }
}


.app-blog{
  .post-detail{
    .post-detail-list{
      margin-bottom: 2rem;
      gap: 1rem;
      display: grid;
      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, 1fr);
      }
      article{
        border: 1px solid #eee;
        padding: 10px;
        h3{
          a{
            color: inherit;
            text-decoration: none;
          }
        }

      }
      .blog-visual{
        margin-bottom: 10px;
        a{
          display: block;
        }
        img{
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}

.agenda_event__date{
  font-size: 2rem;
  font-family: $text_black;
  color: $primary;
  display: flex;
  vertical-align: middle;
  gap: 1rem;
  margin-bottom: 1rem;
  span{
    display: inline-block;
    margin-right: 10px;
  }
}