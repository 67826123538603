html {
  scroll-behavior: smooth;
}
html,
body {
  height: 100%;
}

body{
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  font-family: $text;
  text-rendering: auto;
  transition: transform .3s ease;
  max-width: 100%;
  line-height: 1.5;
  scroll-padding-top: 130px;
}
main{
  flex: 1 0 auto;

}
#footer {
  flex-shrink: 0;
}

.btn{
  &-primary{
    color: white;
  }
}

.breadcrumb{
  .breadcrumb-item{
    display: inline-flex;
    align-items: center;
    a{
      font-weight: bold;
      text-decoration: none;
    }
  }
  .breadcrumb-item+.breadcrumb-item::before{
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    content: "\f054";
    color: $dark;
    font-size: 80%;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
  }
}

.col{
  @include media-breakpoint-only(xs) {
    min-width: 100%;
    & + .col{
      margin-top: 20px;
    }
  }
}