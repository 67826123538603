.plugin__search{
  display: grid;
  gap: 1rem;
  margin-bottom: 3rem;
  @include media-breakpoint-up(lg) {
    // grid-template-columns: 20% 1fr;
  }
  form{
    background-color: darken(white, 3);
    padding: 1rem;
    // border: 1px solid $primary;
    @include media-breakpoint-up(lg) {
      justify-content: center;
      align-items: center;
      text-align: center;
      display: flex;
      flex-direction: column;

    }
    @include media-breakpoint-down(lg) {
      margin-top: 10px;

    }
    input[type="search"]{
      width: 100%;
      @include media-breakpoint-up(lg) {
        min-width: 200px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        border-right: 0;
      }
      border: 1px solid #eee;
      padding: 12px 10px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .btn{
      @include media-breakpoint-up(lg) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        max-width: 160px;
      }
    }
    label{
      font-weight: bold;
      display: block;
      margin-bottom: 10px;
    }
    @include media-breakpoint-down(sm) {
      .d-flex{
        flex-direction: column;
        input[type="search"]{
          margin-bottom: 10px;
        }
      }
    }
  }
}


.search__results{
  ul.search-results__list{
    list-style: none;
    padding-left: 0;
    margin-bottom: 2rem;
    h3{
      font-family: $text_medium;
      font-size: 18px;
      text-transform: uppercase;
      a{
        // color: inherit;
      }
    }
    li{
      padding: 30px;
      // box-shadow: rgba(0, 0, 0, 0.07) 0px 25px 20px -20px;
      // box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
      border: 1px solid #eee;
      &:nth-child(even){
        background-color: rgba($primary, .04);
        border-top: 0;
      }
      p{
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  .highlighted{
    // background-image: linear-gradient(-100deg, rgba(255, 255, 255, 0), yellow 85%, rgba(255, 255, 255, 0));
    display: inline-block;
    color: black;
    text-decoration: none;
    background-position-y: -0%;
    background-image: linear-gradient( white 50%, gold 50%);
    transition: background 500ms ease;
    background-size: 2px;
    background-size: auto 135%;
    font-weight: bold;
    &:hover{
      background-position-y: 100%;
    }
  }
}