.header__site {
  font-family: $text_medium;
  .top__bar {
    background-color: $primary;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 10px;
    text-transform: uppercase;
    a {
      color: white;
      text-decoration: none;
      font-size: 80%;
      letter-spacing: 1px;
      &.contact {
        background-color: darken($primary, 10);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .8rem .8rem;
        &::before{
          content: "";
          height: 18px;
          width: 22px;
          display: inline-block;
          background-image: url(../img/contact.svg);
          background-size: contain;
          background-repeat: no-repeat;
          margin-right: 0.3rem;
        }
      }
    }
    > div {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    .global__nav{
      a{
        position: relative;
        padding: .8rem .4rem;
        transition: all .3s ease-in;
        &::before {
          content: "";
          height: 6px;
          width: 0px;
          background-color: darken($primary, 10);
          position: absolute;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          top: 0px;
          left: 50%;
          transform: translateX(-50%);
          transition: all .3s ease-in;
        }
        &:hover,
        &.active{
          color: darken($primary, 10);
          font-weight: bold;
          &::before{
            width: 60%;
          }
        }
      }
      &.busines{
        a{
          &:hover, &.active{
            color: darken($primary, 20);
            &:before{
              background-color:  darken($primary, 20);
            }
          }
        }
      }
    }
    .dropdown-menu{
      border-radius: 0;
      a{
        color: inherit;
        &:hover{
          color: $primary;
          background-color: rgba($primary, .1);
        }
      }
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  .logo{
    @include media-breakpoint-down(xl) {
      max-width: 130px;
      height: auto;
    }
    @include media-breakpoint-only(lg) {
      max-width: 100px;
    }
  }
  .bottom__bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  ul {
    margin-bottom: 0;
    list-style: none;
    padding-left: 0;
  }

  .menu__nav {
    align-items: center;
    @include media-breakpoint-up(lg) {
      display: flex;
      gap: 2rem;
    }
    @include media-breakpoint-only(lg) {
      gap: 1em;
      margin-left: 10px;
    }
    .nav__link {
      text-transform: uppercase;
      color: $dark;
      text-decoration: none;
      box-shadow: inset 0 0 0 0 $primary;
      transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
      display: inline-block;
      text-align: center;
      font-size: 16px;
      @include media-breakpoint-only(lg) {
        font-size: 13px;
      }
      &:hover {
        // box-shadow: inset 10px 0 0 0 $primary;
        color: $primary;
      }
    }
    .nav__item {
      position: relative;
      .submenu {
        position: absolute;
        left: 50%;
        top: 150%;
        min-width: 280px;
        text-align: left;
        background: #ffffff;
        border: 1px solid #f9f9f9;
        box-shadow: 0 2px 18px 0 rgba(46, 46, 46, 0.13);
        border-radius: 4px;
        z-index: 9999;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        border-bottom: 5px solid $primary;
        transform: translateX(-50%);
        a {
          display: block;
          padding: 0.5rem 1rem;
          text-align: center;
          text-transform: uppercase;
          font-size: 14px;
          font-family: $text_medium;
          color: $dark;
          text-decoration: none;
          transition: all 0.3s ease;
          &:hover {
            background-color: $primary;
            color: white;
          }
          &.active{
            color: $primary;
            &:hover{
              color: white;
            }
          }
        }
      }
      &:hover,
      &.active,
      &.ancestor {
        .nav__link {
          // box-shadow: inset 310px 0 0 0 $primary;
          color: $primary;
        }
      }
      &:hover {
        .submenu {
          top: 100%;
          opacity: 1;
          visibility: visible;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      min-width: 100%;
      .nav__item{
        border-bottom: 1px solid darken(white, 10);
        .nav__link{
          display: block;
          padding:1rem 0;
          text-align: left;
        }
      }
    }
  }
  .btn--search {
    background-color: $primary;
    border: 0;
    color: white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    svg {
      stroke: white;
    }
    @include media-breakpoint-up(sm) {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      border: 4px solid rgba(white, 0.5);

    }
    @include media-breakpoint-down(sm) {
      width: 100%;
      border-radius: 0px;
      padding: 1rem;
    }
  }
}
.menu__nav__mobile.menu__nav{
  @include media-breakpoint-up(sm) {
    display: none;
  }
}
.hidden__xs{
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
}
.form_wrap {
  input[type="text"] {
    border: 0;
    padding: 10px;
  }
}
.navbar-collapse{
  @include media-breakpoint-down(sm) {
    padding: 1rem;
    border-top: 1px solid #eee;
    margin-top: 1rem;
  }
}
/* Search Style */
#searchWrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  transition: all 0.5s ease-in-out;
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
  &.open,
  &:focus-within {
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
    z-index: 1106;
    display: block;
  }
  input[type="submit"]{
    display: block;
    margin-top: 10px;
    background-color: $primary;
    border: 0;
    font-family: $text_black;
    color: white;
    letter-spacing: 1px;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
      padding: 2rem;
      font-size: 2vw;
    }
    @include media-breakpoint-down(lg) {
      padding: 1rem;
    }
  }
  form{
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -51px;
    width: 60%;
    margin-left: 20%;
    @include media-breakpoint-down(lg) {
      width: 80%;
      margin-left: 10%;
    }
    display: flex;
    flex-direction: column;

  }
  input[type="search"] {
    color: $primary;
    background: transparent;
    border-bottom: 6px solid rgba($white, 0.9);
    border-top: 0;
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    @include media-breakpoint-up(lg) {

      font-size: 40px;
    }
    text-align: center;
    outline: none;
    padding: 10px;
  }
  .close {
    position: fixed;
    top: 15px;
    right: 15px;
    opacity: 1;
    font-size: 27px;
    color: #fff;
    &:hover {
      color: #fc2121;
      cursor: pointer;
    }
  }
}
.hamburger {
  background-color: $primary;
  height: 40px;
  width: 40px;
  .hamburger-box {
    transform: scale(0.5);
  }

}
