#id_legalnotice,
#id_error_page{
  .header__site{
    border-bottom: 1px solid darken($white, 13);
  }
  main{
    margin-top: 2rem;
  }

}
#id_error_page{
  main{
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 10vh;
    .container{
      h1{
        font-size: 4vw;
      }
      p{
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
      }
      .btn{
        background-color: $dark;
        color: $white;
      }
    }
  }
}

.legalnotice-list{
  list-style-type: none;
  padding-left: 0;
  >li{
    background-color: darken($white, 3);
    padding: 2rem;
    margin-bottom: 2rem;
    h2{
      font-size: 20px;
    }
  }
}


.breadcrumb{
  .breadcrumb-item + .breadcrumb-item {
    &::before{
      content: "/";
    }
  }
  li{
    @extend .breadcrumb-item;
  }
}

