$fa-font-path: "../fonts/fontawesome";
$slick-font-path: "../fonts/";
$slick-loader-path: "../img/";
$min-contrast-ratio: 3;


$primary: #009F4A;
$secondary: #414142;
$blue: rgb(42, 156, 205);
$bg-gray: #eff3f6;

$btn-border-radius: 2rem;
$btn-padding-y:  .75rem;
$btn-padding-x:     2rem ;
