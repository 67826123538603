footer.footer__site{
  background-color: $secondary;
  padding-top: 3rem;
  color: white;
  background-image: url(../img/sapin.png);
  background-repeat: no-repeat;
  background-position: right top;
  @include media-breakpoint-down(sm) {
    text-align: center;
    h2{
      &::after{
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .logo__footer{
    margin-bottom: 30px;
  }
  a{
    text-decoration: none;
  }
  h2{
    color: white;
    text-transform: uppercase;
    font-size: 24px;
    &::after{
      content: "";
      display: block;
      height: 8px;
      width: 48px;
      background: $primary;
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  .social__links{
    list-style: none;
    padding-left: 0;
    display: flex;
    gap: 1rem;
    a{
      width: 40px;
      height: 40px;
      border-radius: 20px;
      color: $primary;
      border:2px solid;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      transition: all .3s ease;
      text-decoration: none;
      &:hover{
        background-color: $primary;
        color: white;
      }
    }
    @include media-breakpoint-down(sm) {
      justify-content: center;
    }
  }
  .footer__bottom{
    margin-top: 3rem;
    background-color: white;
    color: $dark;
    a{
      color: inherit;
      text-decoration: none;
      display: inline-block;
      &:hover{
        font-weight: bold;
      }
    }
    .nav{
      gap: 1rem;
      li{
        padding: 1rem;
        @include media-breakpoint-down(lg) {
          padding: 0.3rem;
        }
      }
      @include media-breakpoint-down(lg) {
        flex-direction: column;
        text-align: center;
        gap: 0;
      }
    }
  }
}