.home_carousel {
  position: relative;
  .carousel__img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 70vh;
    position: relative;
    overflow: hidden;
    &::before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(119deg, rgba(34,34,34,0.75) 22%, rgba(34,34,34,0.73) 22%, rgba(34,34,34,0.01) 52%);
      @include media-breakpoint-down(sm) {
        background: rgba($dark, .5);

      }
    }
    @include media-breakpoint-down(lg) {
      height: 50vh;
    }
    @include media-breakpoint-down(md) {
      height: 300px;
    }
  }
  .carousel__content {
    position: absolute;
    top: 30%;
    left: 2vw;
    right: 0;
    transform: translateY(-30%);
    color: white;
    z-index: 2;
    padding: 1rem;
    h1,
    h2 {
      font-family: $text_medium;
      text-transform: inherit;
      margin-bottom: auto !important;
      @include media-breakpoint-up(lg) {
        font-size: 32px;
      }
      @include media-breakpoint-only(md) {
        font-size: 22px;
      }
      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
      &::after{
        display: none;
      }
      strong{
        font-family: $text_medium;
        text-transform: uppercase;
        font-size: 20px;
        color: $primary;
      }
    }
    .title_carousel{
      max-width: 600px;
    }
    .btn {
      border: 1px solid #fff;
      color: white;
      transition: all 0.3s ease;
      &:hover {
        background-color: white;
        color: $dark;
      }
    }
  }

  .slick-dots {
    bottom: 70px;
    @include media-breakpoint-down(sm) {
      bottom: -30px;
    }
    li {
      button {
        &::before {
          color: $primary;
          font-family: "Font Awesome 6 Free";
          font-weight: 900;
          content: "\f111";
          font-size: 14px;
        }
      }
    }
  }
  .slick-arrow {
    z-index: 999;
    &::before {
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      color: $primary;
    }

    &.slick-next {
      right: 20px;
      &::before {
        content: "\f054";
      }
    }
    &.slick-prev {
      left: 20px;
      &::before {
        content: "\f053";
      }
    }
  }
}
section {
  h2 {
    text-transform: uppercase;
    position: relative;
    margin-bottom: 2rem;
    &::after {
      content: "";
      display: block;
      height: 8px;
      width: 48px;
      background: $primary;
      border-radius: 4px;
      margin-top: 10px;
    }
    &[style="text-align: center;"]{
      &::after{
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .blog-latest-entries {
    h3 {
      font-family: $text_black;
      text-transform: uppercase;
      font-size: 16px;
      position: relative;
      text-align: center;
      transition: all 0.4s ease;
    }
    article{
      img{
        transition: all 0.4s ease;
      }
      .event__visual{
        position: relative;
        overflow: hidden;
      }
      &:hover {
        h3{
          color: $primary;
        }
      }
    }
  }
}
.fast__links {
  font-family: $text_medium;
  list-style: none;
  padding-left: 0;
  text-align: center;
  gap: 10px;
  @include media-breakpoint-up(md) {
    margin-top: -100px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
  }

  @include media-breakpoint-down(md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 1rem;
    padding: 20px;
  }
  .link {
    height: 100%;
    transition: all 0.3s ease;
    @include media-breakpoint-up(md) {
      min-width: 220px;
      max-width: 220px;
      max-height: 190px;
    }
    @include media-breakpoint-down(md) {
      min-width: 140px;
    }
    a {
      color: inherit;
      text-decoration: none;
      font-size: 15px;
      @include media-breakpoint-up(sm) {
        transition: all 0.3s ease;
        background-color: white;
        overflow: hidden;
        position: relative;
        height: 100%;
        padding: 1rem;
        padding-top: 3rem;
        padding-bottom: 2rem;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        min-height: 180px;
        &::before {
          transition: all 0.3s ease;
          content: "";
          height: 6px;
          width: 100%;
          background-color: $primary;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }

    &__title {
      display: block;
      p {
        margin-bottom: 0;
      }
    }
    .icon {
      position: relative;
      img {
        position: relative;
      }
    }
    svg {
      position: absolute;
      transform: rotate(-45deg);
      left: 50%;
      top: -8px;
      margin-left: -17px;
      rect {
        fill: rgba($primary, 0.6);
      }
      @include media-breakpoint-down(sm) {
        opacity: .3;
      }
    }
    &:hover {
      @include media-breakpoint-up(lg) {
        z-index: 2;
        transform: translateY(-20px);
        a {
          background-color: $primary;
          color: white;

          &::before {
            background-color: darken($primary, 10);
            height: 20px;
          }
          svg {
            transform: rotate(45deg);
            g rect {
              fill: rgba(white, 0.2);
            }
          }
        }
      }
    }
  }
}

.ecology{
  img{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
  }
}
.agenda {
  .blog-latest-entries {
    margin-top: 40px;
    display: grid;
    gap: 1rem;
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
      gap: 3rem;
    }
  }

  .event {
    position: relative;
    border-radius: 10px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    img{
      border-radius: 10px;
      transition:all .3s ease;
    }

    .event__date {
      position: absolute;
      background-color: $primary;
      color: white;
      top: -10px;
      left: 30px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      text-align: center;
      padding: 10px;
      pointer-events: none;
      span {
        color: white;
      }
      .date-range {
        display: flex;
        gap: 0.5rem;
        .date-range-from,
        .date-range-to {
          display: block;
        }
        .date-range-start,
        .date-range-end {
          font-family: $text_black;
        }
      }
    }
    &__content {
      position: absolute;
      pointer-events: none;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      display: flex;
      align-items: flex-end;
      color: white;
      padding: 3rem;
      background-image: linear-gradient(179deg,rgba(0,0,0,0) 0,#000 100%);
      h3 {
        text-transform: uppercase;
        font-family: $text_medium;
        margin-top: 10px;
        color: $white;
        line-height: 1.5;
        font-size: 18px;
        transition: all .4s ease;
      }
      a {
        color: inherit;
        text-decoration: none;
      }
      .event__date {
        color: $primary;
        pointer-events: none;
      }
    }
    &__visual{
      border-radius: 10px;
      a{
        display: block;
      }
    }
    &__lead{
      opacity: 0;
      transition: all .6s ease;
      transform: translateY(130px);
      height: 0;
      overflow: hidden;
    }
    &:hover{
      h3{
        transform: translateY(-30px);
      }
      img{
        transform: scale(1.1) rotate(4deg);
      }
      .event__lead{
        opacity: 1;
        visibility: visible;
        height: 60px;
        transform: translateY(-30px);
      }
    }

  }
}
.agenda__business{
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, $primary 50%, $primary 100%);
  .event{
    text-align: center;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    background-color: $dark;
    h3 {
      text-transform: uppercase;

      margin-top: 10px;
      line-height: 1.5;
      font-size: 18px;
      transition: all .4s ease;
      color: $primary;
      letter-spacing: 1px;
    }
    a {
      color: inherit;
      text-decoration: none;
    }
    .event__date{
      .date-range {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
        color: $white;
        font-size: 20px;
        .date-range-from,
        .date-range-to {
          display: block;
        }
        .date-range-start,
        .date-range-end {
          font-family: $text_black;
        }
        .date-range-on{
          display: none;
        }
      }
    }

    .event__content{
      text-align: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      background-color: transparent;
      pointer-events:none;
      background-image: none;
      padding-top: 2rem;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      padding: 2rem;
      padding-top: 4rem;
      background-image: linear-gradient(179deg,rgba(0,0,0,0) 0,#000 100%);
    }
    .event__visual{
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      background-color: white;
      border-radius: 10px;
      transition: all .3s ease;
      img{
        height: 300px;
        object-fit: cover;
        width: 100%;
        border-radius: 10px;
      }
    }
    &:hover{
      .event__visual{
        opacity: .3;
      }
    }
  }
}

.news,
.agenda_actus,
.agenda,
.publications,
.map,
.section{
  @include media-breakpoint-up(lg) {
    padding: 4rem 0;
  }
  @include media-breakpoint-down(lg) {
    padding: 2rem 0;
  }
}
.wrapper {
  display: flex;
  justify-content: center;
}

.top__page {
  background-color: $bg-gray;
  padding: 3rem 0;
  margin-bottom: 4rem;
  @include media-breakpoint-down(lg) {
    margin-bottom: 0;
    padding-bottom: 1rem;
  }
  h1 {
    text-transform: uppercase;
    position: relative;
    font-size: 30px;
    &::after {
      content: "";
      display: block;
      height: 8px;
      width: 48px;
      background: $primary;
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
}

.post__categories{
  list-style-type: none;
  padding-left: 0;
  display: flex;
  gap: 1rem;
  a{
    font-size: 80%;
    text-decoration: none;
    color: white;
    background-color: $primary;
    border-radius: 5px;
    padding: .2rem .6rem;
  }
}

.news {
  .post {
    text-align: center;
    position: relative;

    h3 {
      padding-top: 20px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 40px;
        height: 6px;
        margin-left: -20px;
        background-color: $primary;
        border-radius: 3px;
        transition: all .4s ease;
      }
      a {
        color: inherit;
        text-decoration: none;
      }
    }
    .read__link {
      font-weight: bold;
      text-decoration: none;
    }
    .post__lead,
    .read__link {
      display: none;
    }
    .blog-visual {
      box-shadow: 0 2px 40px 0 rgba(195, 195, 195, 0.5);
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 20px;
      transition: all 0.3s ease;
      img {
        max-width: 100%;
        min-width: 100%;
        height: auto;
        transition: all 0.3s ease;
        object-fit: cover;
      }
    }
    &__content {
    }
  }
  article{
    &:hover {
      img{
        transform: rotate(-5deg) scale(1.1 , 1.1);
      }
      h3{
        color: $primary;
      }
    }
  }
}
.publications,
.bg-light {
  background-color: $bg-gray;
}

.publications{
  .see__all{
    @include media-breakpoint-up(lg) {

      position: relative;
      margin-top: -80px;
    }
  }
}
.map {
  .map__wrapper {
    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }

    object {
      max-width: 100%;
      height: auto;
    }
  }
}

.mag__item{
  @include media-breakpoint-up(md) {
    background-color: rgba($primary, .3);
    display: grid;
    grid-template-columns: 300px 1fr;
    align-items: center;
    padding: 2rem;
    position: relative;

    &::after{
      content: "";
      height: 100px;
      background-color: $bg-gray;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .mag__informations, .mag__cover{
      position: relative;
      z-index: 1;
    }
    .mag__cover{
      img{
        border: 10px solid $white;
      }
    }
    .mag__informations{
      padding: 2rem;
      margin-top: -100px;
    }
    .mag__title{
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    .mag__links{
      margin-top: 10px;
      gap: 10px;
      display: flex;
      a{
        background: #FFFFFF;
        box-shadow: 0 2px 24px 0 rgba(180,180,180,0.50);
        border-radius: 27px;
        display: inline-block;
        color: inherit;
        text-decoration: none;
        padding: 1rem 2rem;
        font-size: 14px;
        width: auto;
        text-align: center;
        &:hover{
          color: white;
          background-color: $primary;
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    display: grid;
    margin-bottom: 20px;
    align-items: center;
    padding: 1rem;
    a{
      display: block;
      background-color: white;
      border: 1px solid #eee;
      padding: 1rem;
    }
    .mag__informations{
      text-align: center;
      padding: 1rem;
      .mag__title{
        font-weight: bold;
        display: block;
        margin-bottom: 1rem;
      }
    }
    .mag__links{
      display: flex;
      flex-direction: column;
      gap: 1rem;
      a{
        background-color: $primary;
        color: white;
        text-decoration: none;
        border-radius: 30px;
        border:0;
      }
    }
  }
}

.brochure-item {
  a {
    padding: 1rem 2rem;
    border: 2px solid $primary;
    border-radius: 40px;
    text-align: center;
    transition: all 0.3s ease;
    color: inherit;
    font-weight: bold;
    text-decoration: none;
    display: block;
  }
  & + .brochure-item {
    margin-top: 10px;
  }
  &:hover {
    a {
      background-color: $primary;
      color: white;
    }
  }
}

.page__image {
  margin-bottom: -6rem;
  min-width: 100%;
  height: auto;
}

.page_menu {
  background-color: $dark;
  padding: 1rem;
  position: sticky;
  top: 20px;
  &,
  a {
    color: white;
  }
  .page__title {
    font-family: $text_medium;
    font-size: 18px;
  }
  ul {
    list-style-type: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: relative;
    margin-top: 10px;
    &::before {
      content: "";
      width: 6px;
      height: 100%;
      background-color: $primary;
      border-radius: 3px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 10px;
    }
    a {
      font-weight: bold;
      display: block;
      line-height: 2;
      text-decoration: none;
      transition: all 0.3s ease;
      &:hover {
        color: $primary;
      }
    }
  }
}

.wrapper {
  > div {
    min-width: 100%;
  }
  h2 {

    position: relative;
    font-size: 2.4rem;
    letter-spacing: 0;
    font-family: $text_black;
    &::after {
      content: "";
      display: block;
      height: 8px;
      width: 48px;
      background: $primary;
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    &[style="text-align: center;"]{
      &::after{
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  h3{
    font-size: 20px;
  }
  h4, h5, h6{
    font-size: 18px;
  }
  @include media-breakpoint-down(sm) {
    h2{
      font-size: 22px;
    }
  }
}

.bg-primary {
  h2 {
    &::after {
      background: white;
    }
  }
  &.bg-opacity-10{
    h2 {
      &::after {
        background: rgba($primary, .9);
      }
    }
  }
}

.menu_wrapper {
  margin-bottom: 3rem;
  .figure {
    min-height: 150px;
  }

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 400px));
    justify-content: center;
    gap: 1rem;
  }
  @include media-breakpoint-down(lg) {
    .figure{
      margin-bottom: 1rem;
    }
  }

  &.four {
    grid-template-columns: repeat(4, 1fr);
  }
  &.five {
    grid-template-columns: repeat(5, 1fr);
  }
}

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 3rem;
  img {
    max-width: 100%;
    height: auto;
  }
}

.agenda_actus{

  .actus{
    .blog-latest-entries{
      display: grid;
      gap: 1rem;

      @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, 1fr);
      }

      .blog__post{
        padding: 10px;
        border-radius: 5px;
        @include media-breakpoint-up(lg) {
          // background-color: darken(white, 3);
          background-color: white;
          display: grid;
          box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
          grid-template-columns: 40% 60%;
          align-items: center;

        }
        .post__content{
          padding: 1rem;
          h3{
            text-align: left;
            text-transform: none;
            font-size: 20px;
            font-family: $text_medium;
            a{
              text-decoration: none;
              text-transform: none;
            }
          }
          .read__link{
            text-align: right;
            font-family: $text_medium;
            border-bottom: 2px solid $primary;
            text-decoration: none;
            color: inherit;
          }
          .post__lead{
            p{
              line-height: 1.5;
              font-size: 90%;
            }
          }
        }
        .blog-visual{
          @include media-breakpoint-up(lg) {

            overflow: hidden;
            height: 100%;
          }
          a{
            height: 100%;
          }
          img{
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        &:hover{
          h3{
            transform: unset;
          }
        }
      }

    }
  }
}


.section__bg{
  position: relative;
  background-color: $primary;
  margin-bottom: 4rem;
  @include media-breakpoint-up(lg) {

  }

  h2{
    &::after{
      background-color: $white;
    }
  }
  .container{
    position: relative;
    z-index: 1;
  }
  .menu_wrapper{

    @include media-breakpoint-up(md) {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(2, 1fr);
      figure{
        margin-bottom: 0;
      }
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
      margin-bottom: -100px;

    }
    figure{
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-radius: 10px;
      border: 2px solid $white;
    }
  }
}

.dechets{
  padding: 0;
  figure.figure.effect-1{
    background-color: transparent;
    text-align: left;
    img{
      opacity: 1;
      border-radius: 10px;
      height: 200px;
      width: 100%;
      object-fit: cover;
    }
    figcaption{
      color: inherit;
      position: static;
      display: block;
      padding: 1rem;
      margin-top: 1rem;
      text-transform: inherit;
      &::before{
        display: none;
      }
      .title{
        font-family: $text_medium;
        color: $primary;
        margin-bottom: 1rem;
      }
      .title, p{
        transform: unset;
        position: inherit;
        opacity: 1;
        visibility: visible;
        padding: 0;
        text-transform: inherit;
      }
      >a{
        position: static;
        text-indent: 0;
        font-size: 16px;
        opacity: 1;
        font-family: $text_medium;
      }
    }
  }
}



.newsletter{
  background-color: rgba($primary, .3);
  padding: 2rem;
  p:last-child{
    margin-bottom: 0;
  }
  h2{
    font-size: 20px;

  }
}


.events_carousel{
  .blog-latest-entries{
    margin-bottom: 20px;
    display: block;
    gap: 0;

    .event{
      position: relative;
      .event__visual{
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border: 10px solid $white;
        border-radius: 5px;
        background-color: $white;

      }
      .event__content{
        text-align: center;
        padding: 2rem;

        a{
          text-decoration: none;
          color: inherit;
        }
        h3{
          margin-top: 1rem;
          font-size: 20px;
          font-family: $text_medium;
        }
        .event__date{
          pointer-events: none;
          .date-range{
            color: $primary;
            font-family: $text_black;
            display: flex;
            flex-wrap: wrap;
            gap: 0.3rem;
            align-items: center;
            justify-content: center;
          }
        }
      }
      &:hover{
        h3{
          transform: unset !important;
        }
      }
    }
    .slick-dots{
      bottom: 0;
    }
    .slick-dots li button:before{
      font-size: 16px;
    }
    .slick-active{
      button:before{
        color: $primary !important;
      }
    }
  }
}